/* Copyright 2013 - 2022 Waiterio LLC */
/* eslint max-len: 0 */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'

const iconDefault = css`
  fill: none;
  stroke: white;
  stroke-width: 2;
  transition: stroke 0.2s;
  cursor: pointer;

  :hover {
    stroke: #ffa503;
  }
`

const logoDark = css`
  cursor: pointer;
  display: flex;
  align-items: center;

  span {
    font-size: 20px;
    letter-spacing: 2px;
    color: var(--color-base);
    margin-left: 8px;
    transition: color 0.125s ease;
    user-select: none;
  }

  svg {
    stroke: var(--color-base);
  }

  @media (hover: hover) {
    :hover {
      svg {
        stroke: var(--color-secondary);
      }
      span {
        color: var(--color-secondary);
      }
    }
  }

  :active {
    svg {
      stroke: var(--color-secondary-dark);
    }
    span {
      color: var(--color-secondary-dark);
    }
  }
`

const logoLight = css`
  ${logoDark}

  span {
    color: white;
  }

  svg {
    stroke: white;
  }
`

const IconMultilocale = props => {
  const { width = 32, height = 32, light, onClick } = props
  const dataTestId = props['data-testid'] || 'icon-multilocale'

  return (
    <span css={light ? logoLight : logoDark}>
      <svg
        css={iconDefault}
        width={width}
        height={height}
        onClick={onClick}
        data-testid={dataTestId}
        viewBox="0 0 24 24"
      >
        <circle cx="12" cy="12" r="10" />
        <line x1="2" y1="12" x2="22" y2="12" />
        <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z" />{' '}
      </svg>
      <span>MultiLocale</span>
    </span>
  )
}

export default IconMultilocale
