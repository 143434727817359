/* Copyright 2013 - 2022 Waiterio LLC */
import IS_DEVELOPMENT from './IS_DEVELOPMENT.js'
import IS_STAGING from './IS_STAGING.js'
import IS_TESTING from './IS_TESTING.js'
import IS_BROWSER from './IS_BROWSER.js'
import IS_NODE from './IS_NODE.js'

export default function getMultilocaleAppUrl() {
  let url = 'https://app.multilocale.com'

  if (
    IS_BROWSER &&
    window.MULTILOCALE_APP_URL === '{{{MULTILOCALE_APP_URL}}}'
  ) {
    delete window.MULTILOCALE_APP_URL
  }

  if (
    IS_NODE &&
    process.env.MULTILOCALE_APP_URL === '{{{MULTILOCALE_APP_URL}}}'
  ) {
    delete process.env.MULTILOCALE_APP_URL
  }

  if (IS_BROWSER && window.MULTILOCALE_APP_URL) {
    url = `${window.MULTILOCALE_APP_URL}`
  } else if (IS_NODE && process.env.MULTILOCALE_APP_URL) {
    url = process.env.MULTILOCALE_APP_URL
  } else if (IS_STAGING) {
    url = 'https://app.multilocale-staging.com'
  } else if (IS_DEVELOPMENT || IS_TESTING) {
    // url = 'https://app.multilocale-staging.com';
    // url = 'http://192.168.1.64:6262';
    url = 'http://localhost:6262'
  }

  // if (IS_STAGING || IS_DEVELOPMENT) {
  //   console.debug('Multilocale.url = ' + url)
  // }

  return url
}
